import "./App.css";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Products";
import Testimonials from "./components/Testimonials/Testimonials";
import Header from "./components/header/Header";
import Slider from "./components/slider/Slider";
import Virtual from "./components/virtual/Virtual";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Hero></Hero>
      <Slider></Slider>
      <Virtual></Virtual>
      <Products></Products>
      <Testimonials></Testimonials>
      <Footer></Footer>
    </div>
  );
}

export default App;
