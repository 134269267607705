import React from 'react';
import css from './Footer.module.css';
import Logo from '../../assets/logo.png';
import { InboxIcon, PhoneIcon, LocationMarkerIcon, LoginIcon, UsersIcon, LinkIcon, MailIcon } from '@heroicons/react/outline';

const Footer = () => {
    return (
      <div className={css.cFooterWrapper}>
        <hr />

        <div className={css.cFooter}>
          <div className={css.logo}>
            <img src={Logo} alt="" />
            <span>SwiftCart</span>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Contact Us</span>
              <span className={css.pngline}>
                <LocationMarkerIcon className={css.icon}></LocationMarkerIcon>
                <span>111 north avenue Orlando, FL 32801</span>
              </span>
              <span className={css.pngline}>
                <PhoneIcon className={css.icon}></PhoneIcon>
                <a href="#">352-306-4415</a>
              </span>
              <span className={css.pngline}>
                <InboxIcon className={css.icon}></InboxIcon>
                <a href="#">support@amazon.com</a>
              </span>
            </div>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Accounts</span>
              <span className={css.pngline}>
                <LoginIcon className={css.icon}></LoginIcon>
                <span>Sign In</span>
              </span>
            </div>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Company</span>
              <span className={css.pngline}>
                <UsersIcon className={css.icon}></UsersIcon>
                <span>About Us</span>
              </span>
            </div>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Resources</span>
              <span className={css.pngline}>
                <LinkIcon className={css.icon}></LinkIcon>
                <span>Safety Privacy & Terms</span>
              </span>
            </div>
          </div>
        </div>
        <div className={css.copyright}>
          <span>Copyright ©2023 by Shrey Koradiya</span>
          <span>All rights reserved.</span>
        </div>
      </div>
    );
}

export default Footer