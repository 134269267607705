import React from "react";
import css from "./Header.module.css";
import Logo from "../../assets/logo.png";
import { CgShoppingBag } from "react-icons/cg";
import { GoThreeBars } from "react-icons/go";
import { useState } from "react";
import DarkMode from "../darkmode/Darkmode";

const Header = () => {
  const [showMenu, setshowMenu] = useState(false);

  const togglemenu = () => {
    setshowMenu((showMenu) => !showMenu);
  };
  return (
    <div className={css.container}>
      <div className={css.logo}>
        <img src={Logo} alt="logo" />
        <span>SwiftCart</span>
      </div>

      <div className={css.right}>
        <div className={css.bars} onClick={() => togglemenu()}>
          <GoThreeBars></GoThreeBars>
        </div>

        {/* <div className={css.menu}> */}
        <ul
          className={css.menu}
          style={{ display: showMenu ? "inherit" : "none" }}
        >
          <li>Collections</li>
          <li>Brands</li>
          <li>New</li>
          <li>Sales</li>
          <li>ENG</li>
        </ul>
        {/* </div> */}

        <input type="text" className={css.search} placeholder="search" />
        <DarkMode />

        <CgShoppingBag className={css.cart} />
      </div>
    </div>
  );
};

export default Header;
