import React from 'react';
import css from './Virtual.module.css';
import Shade from '../../assets/shade.png';
import ReactCompareImage from 'react-compare-image';
import Before from '../../assets/before.png';
import After from '../../assets/after.png';
import { motion } from "framer-motion";

const Virtual = () => {
  const transition = {duration: 2,type:"linear"}
  return (
    <div className={css.virtual}>
      <div className={css.left}>
        <span>Virtual Try-On</span>
        <span>Never buy the wrong shade again.</span>
        <span>Try Now!</span>
        <img src={Shade} alt="" />
      </div>

      <div className={css.right}>
        <motion.div initial={{left:'2rem',opacity:0}} whileInView={{left:'0rem',opacity:1}} transition={transition} className={css.wrapper}>
          <ReactCompareImage leftImage={Before} rightImage={After} />   {/* used for comparing two images */ }
        </motion.div>
      </div>
    </div>
  )
}

export default Virtual